import { startCase } from 'lodash'
import { convertFilterString, getChannelIcon } from '@/helpers/channels'
import { parseURIComponent } from '@/helpers/textStringConversions'

export default function getFilterVisual(tabs, filters, context) {
  return Object.entries(filters || {}).map(([key, value]) => {
    const tab = tabs.find(
      t =>
        [t.key, t.subtabKey].includes(key) ||
        (Array.isArray(t.values) && t.values.find(tv => tv.subtabKey === key))
    )
    if (!tab) {
      console.warn(`Could not find filter data for key ${key}`)
      return { key, value }
    }

    const { text, icon } = getTextAndIcon(tab, key, value, context)

    return { key, text, icon }
  })
}

function getTextAndIcon(tab, key, value, context) {
  switch (tab.key) {
    case 'flowPages':
    case 'channel':
    case 'flow':
    case 'flowValues':
    case 'medium':
    case 'campaign':
    case 'page':
    case 'domain':
    case 'location':
    case 'daterange':
    case 'other':
    default: {
      return { text: getText(tab, key, value, context), icon: getIcon(tab) }
    }
  }
}

function getText(tab, key, value) {
  switch (tab.key) {
    // case 'flowPages': {
    //   const flowId = value.split('-')[0]
    //   const val = value.split('-').slice(1)
    //   const flows = (context && context.flows) || []
    //   const flow = flows.find(f => f.id === flowId)
    //   const formattedText = `${(flow && flow.title) || flowId} - ${val}`

    //   return `${startCase(key)}: ${formattedText}`
    // }
    case 'channel': {
      const parsedText = parseURIComponent(value)
      return convertFilterString(parsedText, key, { stages: [] })
    }
    case 'other':
      return `${startCase(key)}: ${value}`

    case 'flowPages':
    case 'flow':
    case 'flowValues':
    case 'medium':
    case 'campaign':
    case 'page':
    case 'domain':
    case 'location':
    case 'daterange':
    default: {
      return startCase(value.split(/:|\.|\s-\s|-/).slice(-1)[0])
    }
  }
}

function getIcon(tab) {
  switch (tab.key) {
    case 'channel':
      return getChannelIcon(tab.value)

    case 'flowPages':
    case 'flow':
    case 'flowValues':
    case 'medium':
    case 'campaign':
    case 'page':
    case 'domain':
    case 'location':
    case 'daterange':
    case 'other':
    default: {
      return null
    }
  }
}
