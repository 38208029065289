<template>
  <div class="ChangeDisplay flex flex-col divide-y">
    <div class="text-lg">{{ data.label }}</div>
    <div v-if="data.added.length">
      <div class="font-bold">Added:</div>
      <ChangeElement v-for="d in data.added" :key="d.id" :change="d" :masterId="d.id">
        <div class="flex flex-col pl-4" v-if="Array.isArray(d.componentChanges)">
          <ChangeElement
            v-for="change in d.componentChanges"
            :key="change.id"
            :change="change"
            :subChanges="change.changes"
            :masterId="change.id"
            @click.native.stop.prevent="changeView(change.id)"
          />
        </div>
      </ChangeElement>
    </div>
    <div v-if="data.changed.length">
      <div class="font-bold">Changed:</div>
      <ChangeElement
        v-for="d in data.changed"
        :key="d.id"
        :change="d"
        :subChanges="d.changes"
        :masterId="d.id"
        @click.native.stop.prevent="changeView(d.id)"
      >
        <div class="flex flex-col pl-4" v-if="Array.isArray(d.componentChanges)">
          <ChangeElement
            v-for="change in d.componentChanges"
            :key="change.id"
            :change="change"
            :subChanges="change.changes"
            :masterId="change.id"
            @click.native.stop.prevent="changeView(change.id)"
          />
        </div>
      </ChangeElement>
    </div>
    <div v-if="data.deleted.length">
      <div class="font-bold">Deleted:</div>
      <ChangeElement v-for="d in data.deleted" :key="d.id" :change="d" />
    </div>
  </div>
</template>
<script>
import ChangeElement from './ChangeElement.vue'
export default {
  components: { ChangeElement },
  name: 'ChangeDisplay',
  inject: {
    _getRegistered: { default: () => () => {} },
  },
  provide() {
    return {
      _changeView: this.changeView,
    }
  },
  props: {
    data: Object,
    form: Object,
  },
  computed: {
    editorActions() {
      return this._getRegistered('editorAction') || {}
    },
    setViewType() {
      const action = this.editorActions
      const empty = () => {}
      return action.onViewType || empty
    },
    viewType() {
      const type = this.data.key
      switch (type) {
        case 'styles':
          return null
        case 'pages':
          return 'pages'
        // return 'components'
        case 'computedFields':
          return 'computed_fields'
        case 'experiments':
          return 'experiments'
        case 'dataOutputs':
          return 'data_outputs'
        case 'components':
          return 'global_components'
        case 'flow':
        default:
          return 'flow'
      }
    },
  },
  methods: {
    changeView(id, typeOverride) {
      let viewType = typeOverride || this.viewType
      if (viewType === null) {
        /* Handle Styles here somehow, not sure if its possible since styles are set by the various specific components or custom styles */
        return
      }
      if (this.viewType === 'pages' && !typeOverride) {
        const page = (this.form.pages || []).find(p => p.id === id)
        if (!page) viewType = 'components'
      }
      this.setViewType(viewType, id)
    },
  },
}
</script>
