<template>
  <div class="EditorModeControls">
    <a-button-group>
      <a-tooltip v-for="{ key, label, icon } in modes" :key="key" :title="label">
        <a-button
          :type="(editorMode === key && 'primary') || undefined"
          v-bind="{ icon }"
          @click="editorMode = key"
        />
      </a-tooltip>
    </a-button-group>
  </div>
</template>
<script>
export default {
  name: 'WidthControls',
  computed: {
    editorMode: {
      get() {
        return this.$store.state.editorMode || 'editing'
      },
      set(v) {
        this.$store.commit('setEditorMode', v)
      },
    },
    modes() {
      return [
        { key: 'editing', label: 'Edit Mode', icon: 'edit' },
        { key: 'use', label: 'Interact Mode', icon: 'select' },
        { key: 'preview', label: 'Preview Mode', icon: 'eye' },
      ]
    },
  },
}
</script>
