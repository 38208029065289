<template>
  <div
    class="TopBar flex justify-between items-stretch bg-white border-b border-gray-200 shadow-md"
    style="margin-bottom: 2px;"
  >
    <div class="nav-section justify-start">
      <div
        v-if="isFlowEditor || noAuthComments"
        class="cursor-pointer"
        @click="showLeftBar = !showLeftBar"
      >
        <Icon
          type="far"
          name="layer-group"
          :class="showLeftBar ? 'text-purple' : ''"
          class="breadcrumb"
        />
      </div>
      <template v-if="!isFlowEditor">
        <Icon type="far" v-if="icon" :name="icon" />
        <h1>{{ title }}</h1>
      </template>
      <a-tooltip v-if="!onlineStatus">
        <template slot="title">You might be offline</template>
        <a-icon type="disconnect" style="color:red;" />
      </a-tooltip>
      <template v-if="$route.path.includes('/flows/') && !isFlowEditor">
        <Icon type="far" name="chevron-right" class="breadcrumb" />
        <a-dropdown :trigger="['click']">
          <a-menu slot="overlay" @click="changePage">
            <a-menu-item v-for="{ key, name, icon } in subpages" :key="key">
              <Icon v-if="icon" type="far" :name="icon" class="mr-1" /> {{ name }}
            </a-menu-item>
          </a-menu>
          <div class="subpage-switcher px-2 py-1.5 rounded cursor-pointer hover:bg-gray-100">
            {{ currentSubpage.name }}
            <Icon type="fas" name="sort" class="text-gray-400" />
          </div>
        </a-dropdown>
      </template>
      <template v-if="isFlowEditor">
        <FormVersionViewer :id="$route.params.id" :hideSaveButton="noAuthComments" />
      </template>
    </div>
    <div class="nav-section justify-end">
      <template v-if="isFlow && flowInfo">
        <a-popover placement="bottomRight" trigger="click">
          <template slot="title">Share</template>
          <template slot="content">
            <ShareFlow :id="$route.params.id" :form="flowInfo" />
          </template>
          <div class="cursor-pointer">
            <Icon type="far" name="share" class="breadcrumb" />
            {{ $route.name === 'flow-editor' ? 'Share' : 'Share Flow' }}
          </div>
        </a-popover>
      </template>
      <EditorModeControls v-if="isFlowEditor && !noAuthComments" />
      <WidthControls v-if="isFlowEditor" />
      <NewFilters v-if="setFilters" v-model="filters" />
      <div
        v-if="$route.name === 'people'"
        class="search-bar rounded border border-gray-200 px-4 py-1.5 w-80 flex items-center justify-start bg-white text-gray-400 cursor-pointer hover:shadow hover:text-gray-500 active:text-400 focus:outline-none transition"
      >
        <Icon type="far" name="search" class="mr-3" />
        <input
          class="outline-none w-full"
          placeholder="Search for contacts..."
          v-model="contactSearchTerms"
        />
      </div>

      <template v-else-if="$route.name === 'flow-entries'">
        <a-tag
          v-for="filter in Object.entries($store.state.submissionFilters)"
          :key="filter[0]"
          closable
          @close="onCloseTag(filter[0])"
        >
          {{ formatFilterTag(filter) }}
        </a-tag>
        <button
          class="search-bar rounded border border-gray-200 px-4 py-1.5 w-50 flex items-center justify-start bg-white text-gray-400 cursor-pointer hover:shadow hover:text-gray-500 active:text-400 focus:outline-none transition"
          @click="openSearch"
        >
          <Icon type="far" name="filter" class="mr-3" />
          Filter Contacts by...
        </button>
      </template>
      <button
        v-else-if="!isFlowEditor"
        class="search-bar rounded border border-gray-200 px-4 py-1.5 w-80 flex items-center justify-start bg-white text-gray-400 cursor-pointer hover:shadow hover:text-gray-500 active:text-400 focus:outline-none transition"
        @click="openSearch"
      >
        <Icon type="far" name="search" class="mr-3" />
        Search for contacts...
      </button>
      <a-tooltip v-if="!isFlowEditor && isAdmin" title="Create New...">
        <InsertBlock
          hideBlocks
          :hideInsights="header && header.title === 'People'"
          @insight-added="addInsightCard($event)"
        >
          <Icon name="plus" class="cursor-pointer hover:text-primary" type="far" />
        </InsertBlock>
      </a-tooltip>
      <a-tooltip v-if="reloadData" title="Refresh Data">
        <Icon name="redo" class="cursor-pointer" type="far" @click.native="reloadData" />
      </a-tooltip>
      <!-- <a-tooltip v-if="download" title="Download Data"> -->
      <a-dropdown v-if="download">
        <div
          class="ant-btn ant-btn-link ant-btn-icon-only flex items-center"
          style="color: inherit; margin-left: 8px; margin-right: 0px;"
        >
          <Icon name="download" class="cursor-pointer" type="far" />
        </div>
        <a-menu slot="overlay">
          <a-menu-item @click="download()">Download Current View</a-menu-item>
          <a-menu-item @click="download('large')">Download all entries (max 10,000)</a-menu-item>
          <a-menu-item @click="download('large', true)"
            >Download all entries with this filter (max 10,000)</a-menu-item
          >
          <a-menu-item @click="download('request')">Email me all entries (max 100,000)</a-menu-item>
          <a-menu-item @click="download('request', true)"
            >Email me all entries with filter (max 100,000)</a-menu-item
          >
        </a-menu>
        <div></div>
      </a-dropdown>
      <a-tooltip v-if="isFlowEditor" title="Disable Auto Page turn">
        <Icon
          name="map"
          class="test-data cursor-pointer"
          :type="disableDelay ? 'fas' : 'far'"
          :class="{ active: disableDelay }"
          @click.native="disableDelay = !disableDelay"
        />
      </a-tooltip>
      <a-tooltip v-if="isFlowEditor" title="Show Hidden Pages">
        <Icon
          name="eye-slash"
          class="test-data cursor-pointer"
          :type="showHiddenPages ? 'fas' : 'far'"
          :class="{ active: showHiddenPages }"
          @click.native="showHiddenPages = !showHiddenPages"
        />
      </a-tooltip>
      <a-tooltip v-if="showTestDataButton" title="Show Test Data">
        <Icon
          name="vial"
          class="test-data cursor-pointer"
          :type="showingTestData ? 'fas' : 'far'"
          :class="{ active: showingTestData }"
          @click.native="showingTestData = !showingTestData"
        />
      </a-tooltip>
      <Notifications v-if="!isFlowEditor" />
      <a-tooltip v-if="newMinorVersion" title="Reload to get the new version of the webapp">
        <Icon
          name="exclamation-circle"
          class="cursor-pointer"
          type="far"
          style="color: yellow;"
          @click.native="reloadPage"
        />
      </a-tooltip>
      <div v-if="noAuthComments" class="cursor-pointer" @click="onClickRightBar">
        <Icon
          type="far"
          :name="getRightBarIcon()"
          :class="showRightBar ? 'text-purple' : ''"
          class="breadcrumb"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import { startCase, cloneDeep } from 'lodash'

import Notifications from './Notifications'
import { unpack } from '@/helpers/computed'
import InsertBlock from '@/components/explorer/InsertBlock.vue'
import FormVersionViewer from '@/components/form/editor/FormVersionViewer'
import NewFilters from '@/components/explorer/Filters/NewFilters'
import ShareFlow from './form/editor/ShareFlow.vue'
import WidthControls from './form/editor/mainEditor/WidthControls.vue'
import EditorModeControls from './form/editor/mainEditor/EditorModeControls.vue'
import updateActiveGroup from '@/data/updateActiveGroup'
const Subpages = [
  {
    key: undefined,
    name: 'Overview',
    icon: 'home',
  },
  // {
  //   key: 'preview',
  //   name: ' Preview ',
  //   icon: 'eye',
  // },
  {
    key: 'edit',
    name: ' View + Edit',
    icon: 'edit',
  },
  {
    key: 'entries',
    name: ' Submissions',
    icon: 'users',
  },
]

export default {
  name: 'TopBar',
  inject: ['_getRegistered'],
  components: {
    Notifications,
    InsertBlock,
    NewFilters,
    FormVersionViewer,
    ShareFlow,
    WidthControls,
    EditorModeControls,
  },
  props: {
    page: Object,
    noAuthComments: Boolean,
  },
  data() {
    return {
      filters: {},
      appVer: null,
      initialMinorVersion: 0,
      initialMajorVersion: 0,
    }
  },
  computed: {
    ...mapState(['advancedMode', 'allFlows']),
    ...mapGetters(['isAdmin', 'flows', 'onlineStatus', 'userId']),
    contactSearchTerms: {
      get() {
        return this.$store.state.contactSearchTerms
      },
      set(v) {
        this.$store.commit('setContactSearch', v)
      },
    },
    showLeftBar: {
      get() {
        return this.$store.state.showLeftBar
      },
      set(v) {
        this.$store.commit('setLeftBar', v)
      },
    },
    showRightBar: {
      get() {
        return this.$store.state.showRightBar
      },
      set(v) {
        this.$store.commit('setRightBar', v)
      },
    },
    header() {
      return getPageHeader(this.$route, this.flows) || this.page
    },
    ...unpack('header', ['icon', 'title']),
    showingTestData: {
      get() {
        return this.$store.state.showTestData
      },
      set(v) {
        return this.$store.commit('setTestData', v)
      },
    },
    currentSubpage() {
      const key = this.$route.path.split('/').slice(-1)[0]

      return Subpages.find(s => s.key === key) || Subpages[0]
    },
    subpages() {
      return Subpages
    },
    isFlow() {
      return ['flow-summary', 'flow-editor', 'flow-entries'].includes(this.$route.name)
    },
    isFlowEditor() {
      return ['edit', 'preview'].includes(this.currentSubpage.key) || this.noAuthComments
    },
    flowInfo() {
      return this.allFlows.find(f => f.id === this.$route.params.id)
    },
    disableDelay: {
      get() {
        return this.$store.state.disableDelay || false
      },
      set(v) {
        this.$store.commit('setDisableDelay', v)
      },
    },
    showHiddenPages: {
      get() {
        return this.$store.state.showHiddenPages || false
      },
      set(v) {
        this.$store.commit('setShowHiddenPages', v)
      },
    },
    reloadData() {
      return this._getRegistered('reload')
    },
    setFilters() {
      return this._getRegistered('setFilters')
    },
    download() {
      return this._getRegistered('download')
    },
    toggleAdvancedMode() {
      return this._getRegistered('toggleAdvancedMode')
    },
    showTestDataButton() {
      return ['home', 'people', 'flows', 'flow-summary', 'flow-entries'].includes(this.$route.name)
    },
    minorVersion() {
      return this.appVer && this.appVer.minorVersion
    },
    majorVersion() {
      return this.appVer && this.appVer.majorVersion
    },
    newMinorVersion() {
      if (!this.minorVersion) return false
      return this.minorVersion > this.initialMinorVersion
    },
  },
  async mounted() {
    const versionRef = Firebase.firestore().doc(`webapp/version`)
    await this.$bind('appVer', versionRef)
    const { minorVersion = 0, majorVersion = 0 } = this.appVer || {}
    this.initialMinorVersion = minorVersion
    this.initialMajorVersion = majorVersion
    window.addEventListener('online', this.onOnlineStatus)
    window.addEventListener('offline', this.onOfflineStatus)
    this.initCommandBar()
  },
  beforeDestroy() {
    window.removeEventListener('online', this.onOnlineStatus)
    window.removeEventListener('offline', this.onOfflineStatus)
  },
  watch: {
    filters: {
      handler(f) {
        if (typeof this.setFilters === 'function') this.setFilters(f)
      },
    },
  },
  methods: {
    onOnlineStatus() {
      this.$store.commit('setOnlineStatus', true)
    },
    onOfflineStatus() {
      this.$store.commit('setOnlineStatus', false)
    },
    addInsightCard(id) {
      this._getRegistered('addInsightCard')(id)
    },
    openSearch() {
      if (this.$route.name === 'flow-entries') window.CommandBar.execute(5654)
      else window.CommandBar.open()
    },
    reloadPage() {
      window.location.reload()
      return false
    },
    changePage({ key }) {
      const path = this.$route.path
        .split('/')
        .slice(0, 5)
        .concat(key === 'item_0' ? [] : [key])
        .join('/')

      this.$router.push(path)
    },
    onCloseTag(key) {
      const filters = this.$store.state.submissionFilters || {}
      const newFilters = { ...filters }
      delete newFilters[key]
      this.$store.commit('setSubmissionFilters', newFilters)
    },
    formatFilterTag(filter) {
      return `${startCase(filter[0])}: ${filter[1]}`
    },
    onClickRightBar() {
      if (this.showRightBar) {
        this.previousRightbar = this.showRightBar
        this.showRightBar = false
      } else this.showRightBar = this.noAuthComments ? 'comments' : this.previousRightbar
    },
    getRightBarIcon() {
      if (this.noAuthComments) return 'comments'
      const bar = this.showRightBar || this.previousRightbar
      switch (bar) {
        case 'comments':
          return 'comments'
        case 'triggers':
          return 'random'
        case 'design':
          return 'paint-brush'

        case 'options':
        default:
          return 'tools'
      }
    },
    initCommandBar() {
      if (!window.CommandBar) return
      window.CommandBar.addCallback('duplicateFlow', async args => {
        const groupId = args.group.id
        const flow = this.allFlows.find(f => f.id === args.flow.id)
        const id = await this.dupeFlow(flow, groupId, args.title || `Copy of ${args.flow.label}`)
        const url = `/project/flows/${id}`
        if (this.activeGroupId !== groupId) await updateActiveGroup(this.userId, groupId, true)
        this.$router.push(url)
      })
      window.CommandBar.addCallback('moveFlowToGroup', async args => {
        const groupId = args.group.id
        const flow = this.allFlows.find(f => f.id === args.flow.id)
        await this.moveFlowToGroup(groupId, flow.id)
      })
    },
    async dupeFlow(flow, groupId, title) {
      const batch = Firebase.firestore().batch()
      const newDoc = Firebase.firestore()
        .collection('forms')
        .doc()
      const newFlow = cloneDeep(flow)
      // const builderVersion = (newFlow._meta && newFlow._meta.builder_version) || 2
      delete newFlow._meta
      if (!newFlow.pages) newFlow.pages = []
      const formData = { ...newFlow, id: newDoc.id, title }
      const data = {
        form: JSON.stringify(formData),
        groupId,
        version: 0,
        isLive: false,
        createdAt: new Date().toISOString(),
        // builder_version: builderVersion,
      }
      const historyRef = newDoc.collection('history').doc(`version-0`)
      const userName =
        this.$store.getters.user.nickname ||
        (this.$store.getters.user.auth &&
          (this.$store.getters.user.auth.displayName || this.$store.getters.user.auth.email))
      const author = { userId: this.$store.getters.userId, userName: userName || 'Unnamed User' }
      batch.set(historyRef, {
        groupId: groupId,
        form: JSON.stringify(formData),
        author,
        saved: true,
        status: 'prod',
        version: 0,
        from_version: null,
        updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      // await newDoc.set(data)
      batch.set(newDoc, data)
      await batch.commit()
      return newDoc.id
    },
    async moveFlowToGroup(groupId, flowId) {
      const baseRef = Firebase.firestore()
        .collection('forms')
        .doc(flowId)

      const batch = Firebase.firestore().batch()
      const snap = await baseRef
        .collection('history')
        .orderBy('version', 'desc')
        .limit(20)
        .get()
      snap.forEach(d => batch.update(d.ref, { groupId }))
      batch.update(baseRef, { groupId })
      // await baseRef
      //   .update({ groupId })
      await batch.commit()
    },
  },
}

function getPageHeader(route, flows) {
  if (route.path.startsWith('/settings')) return { title: 'Settings', icon: 'cog' }
  switch (route.name) {
    case 'projects':
      return { title: 'Projects', icon: 'home' }
    case 'integrations':
      return { title: 'Integrations', icon: 'plug' }
    case 'flow-summary':
    case 'flow-editor':
    case 'flow-entries': {
      const flowName = flows ? (flows.find(f => f.id === route.params.id) || {}).title : ''
      return { title: flowName, icon: 'map-signs' }
    }
    default:
      return
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.TopBar {
  font-size: 15px;
  position: relative;
  z-index: 200;
  margin-bottom: 2px;

  .nav-section {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 0.375rem 0.75rem;

    > * {
      margin: 0 0.5rem;
    }
  }

  .Icon {
    font-size: 20px;

    &.breadcrumb {
      font-size: 16px;
      margin-right: 0;
    }
    &.fa-redo {
      font-size: 19px;
    }
    &.fa-plus {
      font-size: 21px;
      padding-bottom: 1px;
    }
  }
  h1,
  .subpage-switcher {
    font-size: 16px;
  }
  .subpage-switcher {
    .Icon {
      font-size: 14px;
      margin: 0 0 0 0.5em;
      padding-top: 2px;
    }
  }

  .search-bar {
    &:active {
      transition: none;
      color: rgba(156, 163, 175);
    }

    .Icon {
      font-size: 18px;
    }
  }

  .Icon.test-data {
    &.active {
      color: $savvy;
    }
  }
  .Notifications {
    margin-left: 0.25rem;
  }
}
</style>
