export function getPageId(path) {
  return path.split('/').slice(-1)[0]
}

export function getRootPath(path) {
  return path
    .split('/')
    .slice(0, -2)
    .join('/')
}
