<template>
  <div class="mini flex flex-row-reverse items-center">
    <Pill v-for="filter in miniFilterDisplay" :key="filter.key" class="mini-filter">
      <!-- <span>{{ filter.key | caps }}</span>
      <span>: </span> -->
      <img
        v-if="filter.icon && filter.icon.startsWith('http')"
        :src="filter.icon"
        class="max-w-4 max-h-4"
        :style="{ transform: size ? `scale(${size})` : 'none' }"
      />
      <Icon v-else-if="filter.icon" type="fab" :name="filter.icon" />

      <span v-if="filter.text">{{ formatText(filter.text) }}</span>
      <Icon
        name="times"
        class="cursor-pointer hover:text-gray-400"
        style="margin-left: .35rem; margin-bottom: -0.15em"
        @click.native="removeFilter(filter.key)"
      />
    </Pill>

    <MiniFilters
      v-if="filtersLoaded && !rocksetFilterSelected"
      useCommandBar
      :tabs="tabs"
      :hasFilters="miniFilterDisplay.length > 0"
      @selectTab="onTabSelect"
    />
  </div>
</template>

<script>
import { parseURIComponent, toCapitalCase } from '@/helpers/textStringConversions'
import { getStartOfDay, getStartOfWeek, getStartOfMonth } from '@/helpers/timeFilters'
import { getCleanUrl } from '@/helpers/getCleanUrl'
// import computeFilters from '@/helpers/filters'
import getFilterVisual from './filterDisplay'
import api from '@/helpers/api'
import { mapGetters, mapState } from 'vuex'
import { startCase } from 'lodash'
import getCheckpointOptions from '@/components/form/editor/helpers/getCheckpointOptions'

import MiniFilters from '@/components/explorer/Filters/MiniFilters.vue'
export default {
  components: { MiniFilters },
  props: {},
  data() {
    return {
      readableFilters: {},
      // ready: true,
    }
  },
  computed: {
    ...mapState(['rocksetGroup', 'rocksetLoading']),
    ...mapGetters(['flows', 'activeGroupId']),
    rockset() {
      return this.$store.getters.rocksetFilterValues || {}
    },
    filtersLoaded() {
      return Object.values(this.rocksetLoading || {}).every(v => !v)
    },
    rocksetFilterSelected() {
      return ['channel', 'medium', 'campaign'].some(f => this.readableFilters[f])
    },
    tabs() {
      const tabs = [
        // {
        //   key: 'priority',
        //   text: 'Priority',
        //   values: this.allTabValues.priority,
        // },
        // {
        //   key: 'journey',
        //   text: 'Funnels',
        //   subtabKey: 'stage',
        //   values: this.allTabValues.journey,
        // },
        {
          key: 'flow',
          text: 'Flow Checkpoints',
          subtabKey: 'flow',
          values: this.allTabValues.flow,
        },
        {
          key: 'flowPages',
          text: 'Flow Pages',
          subtabKey: 'flow-pages',
          values: this.allTabValues.flowPages,
        },
        {
          key: 'flowValues',
          text: 'Flow Values',
          subtabKey: 'flow-values',
          values: this.allTabValues.flowValues,
        },
        {
          key: 'channel',
          text: 'Channels',
          values: this.allTabValues.channel,
        },
        {
          key: 'medium',
          text: 'Mediums',
          values: this.allTabValues.medium,
        },
        {
          key: 'campaign',
          text: 'Campaign',
          values: this.allTabValues.campaign,
        },
        {
          key: 'page',
          text: 'Web Pages',
          subtabKey: 'page',
          values: this.allTabValues.page,
        },
        {
          key: 'domain',
          text: 'Web Domains',
          values: this.allTabValues.domain,
        },
        {
          key: 'location',
          text: 'Location',
          values: this.allTabValues.location,
        },
        {
          key: 'daterange',
          text: 'Time',
          values: this.allTabValues.daterange,
        },
        // {
        //   key: 'eventId',
        //   text: 'Events',
        //   values: this.allTabValues.eventId,
        // },
        {
          key: 'other',
          text: 'Other',
          values: this.allTabValues.other,
        },
      ]
      return tabs
    },
    allTabValues() {
      // { value, text, values: [{value, text}] }
      const values = {
        // priority: this.priorityTabValues,
        // journey: this.journeyValues,
        channel: this.channelValues,
        campaign: this.campaignValues,
        medium: this.mediumValues,
        domain: this.domainValues,
        page: this.pageValues,
        // eventId: this.eventIdValues,
        location: this.locationValues,
        daterange: this.daterangeValues,
        flow: this.flowValues,
        flowPages: this.flowPagesValues,
        flowValues: this.flowValuesValues,
        other: [
          {
            value: 'contact_details',
            subtabKey: 'contact_details',
            text: 'Has Contact Info',
            values: [
              { text: 'True', value: 'true' },
              { text: 'False', value: 'false' },
            ],
          },
        ],
      }
      return values
    },
    channelValues() {
      const channelsFromRockset = this.arrayToButtons(this.rockset.channel)
      return this.filterButtonsByAlias('channel', channelsFromRockset)
    },
    campaignValues() {
      const buttons = this.arrayToButtons(this.rockset.campaign)
      return this.filterButtonsByAlias('campaign', buttons)
    },
    mediumValues() {
      const buttons = [...this.arrayToButtons(this.rockset.medium, { cpc: 'Ads' })]
      return buttons
    },
    domainValues() {
      return this.pageValues.map(v => ({ ...v, values: null }))
    },
    pageValues() {
      const pagesByDomain = Object.values(
        (this.rockset.page || []).reduce((acc, p) => {
          p.domains.forEach(d => {
            if (!d) return
            const text = getCleanUrl(d).replace('www.', '')
            acc[text] = acc[text] || { text, value: d, values: [], sum: 0 }
            acc[text].values.push(p)
            acc[text].sum += p._count
          })

          return acc
        }, {})
      )
        .map(pg => ({
          ...pg,
          values: pg.values
            .map(p => {
              const value = p.value
                ? p.value
                    .split('/')
                    .slice(1)
                    .join('/')
                : p.value
              const text = parseURIComponent(
                value ? `/${value}` : pg.value === value ? '/' : p.value
              )
              return { text, value: p.value }
            })
            .filter(p => p.value),
        }))
        .sort((a, b) => b.sum - a.count)

      const moreThanOneDomain = pagesByDomain.length > 1

      if (!moreThanOneDomain)
        return (this.rockset.page || [])
          .map(p => {
            const value = p.value
              ? p.value
                  .split('/')
                  .slice(1)
                  .join('/')
              : p.value
            const text = parseURIComponent(value ? `/${value}` : p.value)
            return { text, value: p.value }
          })
          .filter(p => p.value)
      else return pagesByDomain
    },
    flowValues() {
      return this.$store.getters.flows.map(f => {
        const values = getCheckpointOptions(f).map(c => ({
          text: startCase(c),
          value: `${f.id}-${c}`,
        }))
        return {
          text: f.title,
          value: f.id,
          values,
        }
      })
    },
    flowPagesValues() {
      return this.$store.getters.flows.map(f => {
        const values = (f.pages || []).map(c => ({
          text: startCase(c.key),
          value: `${f.id}-${c.key}`,
        }))
        return {
          text: f.title,
          value: f.id,
          values,
        }
      })
    },
    flowValuesValues() {
      const buttons = this.rockset.flowValues || []
      return buttons.map(b => ({
        text: b.aliases[0],
        value: b.key,
        values: Array.isArray(b.subValues)
          ? b.subValues.map(v => ({ text: v.aliases[0], value: v.key }))
          : undefined,
      }))
    },
    locationValues() {
      return [
        { subtabKey: 'continent', value: 'continent', text: 'Continent' },
        { subtabKey: 'country', value: 'country', text: 'Country' },
        { subtabKey: 'city', value: 'city', text: 'City' },
        { subtabKey: 'region', value: 'region', text: 'State / Region' },
      ].map(v => ({
        ...v,
        values: this.arrayToButtons(this.rockset[v.value], { __null: 'Unknown' }),
      }))
    },
    daterangeValues() {
      /* idk wtf this is supposed to be */
      const buttons = [
        { text: 'Today', value: 'today' },
        { text: 'Yesterday', value: 'yesterday' },
        { text: 'This Week', value: 'week' },
        { text: 'This Month', value: 'month' },
        { text: 'Custom', value: 'custom', isCustomPicker: true },
      ]

      return [
        {
          text: 'First Interacted At',
          value: 'createdAt',
          subtabKey: 'createdAt',
          values: buttons,
        },
        {
          text: 'Last Interacted At',
          value: 'updatedAt',
          subtabKey: 'updatedAt',
          values: buttons,
        },
      ]
    },
    presetTimeValues() {
      const now = Date.now()
      return {
        today: getStartOfDay(now),
        yesterday: getStartOfDay(now - 24 * 60 * 60 * 1000),
        week: getStartOfWeek(now),
        month: getStartOfMonth(now),
      }
    },
    // filters() {
    //   const keys = this.tabs.reduce(
    //     (acc, t) => {
    //       acc.push(t.key)
    //       if (t.subtabKey) acc.push(t.subtabKey)
    //       if (Array.isArray(t.values)) {
    //         t.values.forEach(v => {
    //           if (v.subtabKey) acc.push(v.subtabKey)
    //         })
    //       }

    //       return acc
    //     },
    //     ['contacttype']
    //   )
    //   const allowedFilters = Array.from(new Set(keys))

    //   if (this.selectedTabId === 'location' && this.selectedSubTabId)
    //     allowedFilters.push(this.selectedSubTabId)

    //   const simpleFilters = allowedFilters
    //     .map(key => [key, this.queryFilters[key]])
    //     .filter(sf => sf[1] !== undefined)

    //   return computeFilters(simpleFilters, {
    //     aliases: this.aliases,
    //     rocksetValues: this.rockset,
    //     dateTime: {
    //       timestampKey: this.timestampKey,
    //       presetTimeValues: this.presetTimeValues,
    //     },
    //   })
    // },
    miniFilterDisplay() {
      return getFilterVisual(this.tabs, this.readableFilters, { flows: this.flows })
    },
  },
  mounted() {
    this.initCommandBar()
    // this.timer = setTimeout(() => (this.ready = true), 3000)
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  watch: {
    tabs: {
      handler(v) {
        if (window.CommandBar) {
          window.CommandBar.addContext({
            filters: v.map(v => ({
              ...v,
              values: Array.isArray(v.values)
                ? v.values.map(va =>
                    Array.isArray(va.values)
                      ? va
                      : { ...va, values: [{ text: 'Hit return to add filter', key: 'N/A' }] }
                  )
                : [],
            })),
          })
        }
      },
      immediate: true,
    },
    activeGroupId: {
      handler(v, old) {
        if (old !== undefined && v) this.init(true)
        else if (v) this.init()
      },
      immediate: true,
    },
    readableFilters: {
      handler(readableFilters) {
        this.$emit('input', readableFilters)
      },
      immediate: true,
    },
  },
  methods: {
    initCommandBar() {
      const self = this
      if (window.CommandBar) {
        window.CommandBar.addCallback('pickFilter', args => {
          const { filter, tab, value } = args

          /*
          Example:
          Find Tab using key from this.tabs
          Find subtab using tab.values.find(v=>v.value===secondValue)
          const data = {
            "tab": "flow", // tab.key
            "subtabKey": "flow", // grab tab.subtabKey from tab
            "subtab": "FK4hbDO8fjmJsP2wAXfP", //
            "value": "_complete-FK4hbDO8fjmJsP2wAXfP" // third value
          }
          */
          const noSubtab = value.key === 'N/A'
          const data = {
            tab: filter.key,
            subtabKey: filter.subtabKey,
            subtab: tab.value,
            value: noSubtab ? tab.value : value.value,
          }

          self.onTabSelect(data)
        })
      }
    },
    init(reload) {
      if (this.activeGroupId !== this.rocksetGroup || reload)
        this.$store.commit('clearRocksetValues')
      this.$store.commit('setRocksetGroup', this.activeGroupId)
      Object.keys(this.rockset).forEach(k => {
        k === 'page'
          ? this.getFilterValuesRockset('page', { limit: 500, raw: true })
          : this.getFilterValuesRockset(k)
      })
    },
    async getFilterValuesRockset(filterName, options) {
      const rocksetFilter = (this.rockset && this.rockset[filterName]) || []
      if (rocksetFilter.length !== 0 || this.$store.getters.rocksetLoading(filterName)) return
      this.$store.commit('setRocksetLoading', { key: filterName, value: true })

      try {
        const result = await api(`/filter-values/${filterName}`, {
          groupId: this.activeGroupId,
          ...options,
        })
        const values = result.data.values || []

        this.$store.commit('setRocksetValues', { key: filterName, values })
      } catch (error) {
        console.error(error)
      }
      this.$store.commit('setRocksetLoading', { key: filterName, value: false })
    },
    removeFilter(name) {
      const base = { ...this.readableFilters }
      delete base[name]

      this.setReadableFilter(base)
    },
    onTabSelect(e) {
      const newfilter = {}

      if (e.subtab) {
        const key = e.value === e.subtab ? e.tab : e.subtabKey || e.subtab
        newfilter[key] = e.value
      } else newfilter[e.tab] = e.value

      this.setReadableFilter(newfilter, true)
    },
    setReadableFilter(filterObj, merge) {
      this.readableFilters = merge ? { ...this.readableFilters, ...filterObj } : filterObj
    },
    filterButtonsByAlias(name, buttons, preserveCase) {
      const lowerButtons = buttons.map(b => ({
        ...b,
        value: typeof b.value === 'string' && !preserveCase ? b.value.toLowerCase() : b.value,
      }))
      const aliases = this.aliases && this.aliases[name]
      if (aliases) {
        const allAliases = Object.values(aliases)
          .reduce((acc, arr) => [...acc, ...arr], [])
          .map(a => (preserveCase ? a : a.toLowerCase()))
        return lowerButtons.filter(b => !allAliases.some(a => b.value === a))
      }
      return lowerButtons
    },
    formatText,
    arrayToButtons,
  },
}

function formatText(t) {
  if (t && t.startsWith('date')) {
    return t
      .slice(4)
      .split('date')
      .map(d => new Date(d).toLocaleDateString())
      .join(' - ')
  }
  return t
}
function arrayToButtons(dataArray, overrides) {
  return (dataArray || []).map(t => {
    const convertedText = t.key === '__null' ? 'Organic' : parseURIComponent(toCapitalCase(t.key))
    const text = overrides && overrides[t.key] ? overrides[t.key] : convertedText
    return { text, value: t.key }
  })
}
</script>
