<template>
  <div class="WidthControls">
    <a-button-group>
      <a-tooltip
        v-for="{ key, name, icon } in screenSizes.filter(s => s.popular)"
        :key="key"
        :title="name"
      >
        <a-button
          :type="(viewport === key && 'primary') || undefined"
          v-bind="{ icon }"
          @click="viewport = key"
        />
      </a-tooltip>
      <a-dropdown>
        <a-button
          :type="(!['full', 'tablet', 'mobile'].includes(viewport) && 'primary') || undefined"
          icon="more"
        />
        <a-menu slot="overlay" :selectedKeys="[viewport]">
          <a-menu-item
            v-for="{ key, name, icon } in screenSizes.filter(s => !s.popular)"
            :key="key"
            @click="viewport = key"
          >
            <a-icon :type="icon" />{{ name }}
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-button-group>
  </div>
</template>
<script>
import { universalIdToProper } from '@/helpers/textStringConversions'
import getWidths from '@/components/form/editor/helpers/widths'
export default {
  name: 'WidthControls',
  computed: {
    viewport: {
      get() {
        return this.$store.state.viewport || 'full'
      },
      set(v) {
        this.$store.commit('setEditorViewport', v)
      },
    },
    screenSizes() {
      return getWidths().map(s => ({
        name: universalIdToProper(s.key)
          .split('Iphone')
          .join('iPhone'),
        ...s,
      }))
    },
  },
}
</script>
