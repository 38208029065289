<template>
  <a-popover v-if="versionDiffs" trigger="click">
    <template slot="title">
      <span>
        Comparing:
        <a-dropdown>
          <span
            class="subpage-switcher px-2 py-1.5 mb-0.5 rounded cursor-pointer hover:bg-gray-100"
          >
            {{ currentFlowButton && currentFlowButton.short }}
          </span>
          <a-menu slot="overlay">
            <a-menu-item
              v-for="button in formButtons"
              :key="button.key"
              @click="compareVersionAId = button.key"
            >
              {{ button.text }}
              <strong>{{ button.editsText }}</strong>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        to
        <!-- <a-dropdown> -->
        <span class="subpage-switcher px-2 py-1.5 mb-0.5 rounded cursor-pointer hover:bg-gray-100">
          {{ textLabel }}
        </span>
        <!-- <a-menu slot="overlay">
            <a-menu-item key="unsaved" @click="compareVersionAId = 'unsaved'">
              Unsaved Changes
            </a-menu-item>
            <a-menu-item
              v-for="button in formButtons"
              :key="button.key"
              @click="compareVersionAId = button.key"
            >
              {{ button.text }}
              <strong>{{ button.editsText }}</strong>
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
      </span></template
    >
    <template slot="content">
      <div class="VersionCompare">
        <template v-if="changeDataDisplay && versionDiffData.length > 0">
          <ChangeDisplay
            v-for="data in versionDiffData"
            :key="data.key"
            :data="data"
            :form="changeDataDisplay.form"
          />
        </template>
        <div v-else>No Detected Changes (This feature is still in beta)</div>
      </div>
    </template>
    <a-icon type="info-circle" />
  </a-popover>
</template>
<script>
import { diffForms } from '@/components/form/editor/helpers/formHelpers.js'
import { mapGetters, mapState } from 'vuex'

import ChangeDisplay from './ChangeDisplay.vue'

export default {
  name: 'VersionCompare',
  components: { ChangeDisplay },
  props: {
    allFormVersions: Array,
    formHistories: Array,
    formButtons: Array,
  },
  data() {
    return {
      compareVersionAId: null,
      // compareVersionBId: null,
    }
  },
  computed: {
    ...mapState(['versionData', 'hasUnsavedFlowChanges']),
    ...mapGetters(['userId']),
    currentFlowButton() {
      let id = null
      if (this.compareVersionAId) id = this.compareVersionAId
      else if (this.hasUnsavedFlowChanges && this.unsavedHistory) id = this.currentHistory.id
      else if (this.currentHistory && this.previousHistory) id = this.previousHistory.id

      return this.formButtons.find(f => f.key === id)
    },
    version() {
      return this.versionData && this.versionData.version
    },
    versionId() {
      return this.versionData && this.versionData.id
    },
    unsavedHistory() {
      const unsaved = this.allFormVersions.find(
        f => f.author.userId === this.userId && f.saved === false && f.version === this.version
      )
      return unsaved
    },
    currentHistory() {
      const ver = this.formHistories.find(f => this.versionId === f.id)
      return ver
    },
    previousHistory() {
      const ver = this.formHistories.find(f => `version-${this.version - 1}` === f.id)
      return ver
    },
    versionAHistory() {
      if (this.compareVersionAId === 'unsaved') return this.unsavedHistory
      const ver = this.formHistories.find(f => this.compareVersionAId === f.id)
      return ver
    },
    // versionBHistory() {
    // /* Doesn't work at the moment and causes the Editor to freak out so best not to change anything */
    //   if (this.compareVersionBId === 'unsaved') return this.unsavedHistory
    //   const ver = this.formHistories.find(f => this.compareVersionBId === f.id)
    //   return ver
    // },
    baseFlowData() {
      // if (this.compareVersionBId) return this.versionBHistory.form

      return this.hasUnsavedFlowChanges && this.unsavedHistory
        ? JSON.parse(this.unsavedHistory.form)
        : this.currentHistory.form
    },
    textLabel() {
      // if (this.versionBHistory) return `v${this.versionBHistory.version}`
      return this.hasUnsavedFlowChanges && this.unsavedHistory
        ? 'unsaved changes'
        : `v${this.currentHistory.version}`
    },
    changeDataDisplay() {
      let label = this.textLabel
      if (this.versionAHistory) label = `Changes from v${this.versionAHistory.version}`
      return {
        label,
        form: this.baseFlowData,
      }
    },
    versionDiffs() {
      let compareVer = null
      if (this.versionAHistory) {
        compareVer = this.versionAHistory.form
      } else if (this.hasUnsavedFlowChanges && this.unsavedHistory) {
        compareVer = this.currentHistory.form
      } else if (this.currentHistory && this.previousHistory) {
        compareVer = this.previousHistory.form
      }
      return compareVer ? diffForms(compareVer, this.baseFlowData) : null
    },
    versionDiffData() {
      if (!this.versionDiffs) return []
      const keyToLabel = k => {
        switch (k) {
          case 'styles':
            return 'Design'
          case 'computedFields':
            return 'Computed Fields'
          case 'experiments':
            return 'Experiments'
          case 'dataOutputs':
            return 'Data Outputs'
          case 'components':
            return 'Global Components'
          case 'flow':
            return 'Flow Options'
          case 'pages':
          default:
            return 'Pages'
        }
      }
      /* Showing diff for objects / arrays inside of components is somewhat annoying to set up so skipped for now */
      return Object.entries(this.versionDiffs)
        .map(([key, data]) => {
          const added = data.filter(d => d.status === 'new')
          const deleted = data.filter(d => d.status === 'deleted')
          const changed = data.filter(d => d.status === 'changed')

          return {
            key,
            label: keyToLabel(key),
            added,
            deleted,
            changed,
            hasChanges: data.length > 0,
          }
        })
        .filter(d => d.hasChanges)
    },
  },
  watch: {},
  methods: {},
}
</script>
