<template>
  <div class="IncludeFonts">
    <link
      v-for="f in fonts"
      :key="f"
      :href="`https://fonts.googleapis.com/css2?${f}&amp;display=swap`"
      rel="stylesheet"
      type="text/css"
    />
  </div>
</template>
<script>
import googleFontsUrls from '@/helpers/googleFontsUrls'

export default {
  props: {
    flows: Array,
  },
  computed: {
    fonts() {
      return this.$store.state.fonts || []
    },
    fontFamilies() {
      if (!this.flows) return []
      const fonts = this.flows.reduce((acc, flow) => {
        const styles = (flow && flow.styles) || {}
        const flowFonts = googleFontsUrls(styles)
        acc.push(...flowFonts)
        return acc
      }, [])
      return Array.from(new Set(fonts))
    },
  },
  watch: {
    fontFamilies: {
      handler(v, old) {
        const newVals = new Set(v)
        const olds = old ? old.filter(f => !newVals.has(f)) : []
        this.$store.commit('setNewFont', v, olds)
      },
      immediate: true,
    },
  },
}
</script>
