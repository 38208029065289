<template>
  <div class="ChangeElement flex flex-col cursor-pointer">
    <div>
      <a-tooltip>
        <template>
          <span>
            <template v-if="change.indexes && change.typeLabel">
              <template v-if="change.indexes[1] !== undefined && change.indexes[1] !== -1">
                {{ change.typeLabel }} {{ change.indexes[1] + 1 }}:
              </template>
              <template v-else-if="change.indexes[0] !== undefined && change.indexes[0] !== -1">
                {{ change.typeLabel }} {{ change.indexes[0] + 1 }}:
              </template>
            </template>
            {{ change.key }}
          </span>
          <span v-if="change.status === 'deleted'"> (Deleted)</span>
          <span v-else-if="change.status === 'new'"> (New)</span>
          <span v-else-if="change.moved"> (Moved)</span>
        </template>
        <template slot="title">
          <span v-if="Array.isArray(change.values) && change.values.length === 2">
            {{ addTags(change.values[0]) }} -> {{ addTags(change.values[1]) }}
          </span>
        </template>
      </a-tooltip>
    </div>
    <div class="flex flex-col pl-4" v-if="Array.isArray(subChanges)">
      <ChangeElement
        v-for="subChange in subChanges"
        :key="subChange.id"
        :change="subChange"
        :masterId="masterId"
        @click.native.stop.prevent="_changeView(masterId || subChange.id)"
      />
    </div>
    <slot />
  </div>
</template>
<script>
export default {
  name: 'ChangeElement',
  inject: { _changeView: { default: () => () => {} } },
  props: { change: Object, subChanges: Array, masterId: String },
  methods: {
    addTags(val) {
      switch (typeof val) {
        case 'string':
          return `"${val}"`

        default:
          return val
      }
    },
  },
}
</script>
