<template>
  <Pill v-if="useCommandBar" clickable dashed class="text-gray-500" @click.native="onClick">
    + Add{{ hasFilters ? '' : ' Filter' }}
  </Pill>
  <a-cascader
    v-else
    class="text-gray-500"
    :options="options"
    v-model="editingValue"
    @change="onChange"
  >
    <Pill clickable dashed>
      + Add Filter
    </Pill>
  </a-cascader>
</template>

<script>
export default {
  name: 'MiniFilters',
  components: {},
  props: {
    useCommandBar: Boolean,
    tabs: { type: Array, default: () => [] },
    hasFilters: Boolean,
  },
  data() {
    return {
      editingValue: null,
    }
  },
  computed: {
    options() {
      return this.tabs.filter(({ key }) => key !== 'priority').map(tabToOption)
    },
  },
  methods: {
    onChange([tab, ...values], fullValues) {
      this.editingValue = null

      const data = {
        tab,
        subtabKey: (fullValues.find(fullValues => fullValues.subtabKey) || {}).subtabKey,
        subtab: values.length === 2 ? values[0] : null,
        value: values.slice(-1)[0],
      }

      this.$emit('selectTab', data)
    },
    onClick() {
      window.CommandBar.execute(603)
    },
  },
}

function tabToOption({ key, value, text, values, subtabKey }) {
  return {
    value: key || value,
    label: text,
    children: values ? values.map(tabToOption) : [],
    subtabKey,
  }
}
</script>

<style lang="scss"></style>
