<template>
  <div class="Notifications">
    <div ref="notificationsInbox" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Notifications',
  computed: {
    ...mapGetters(['userId']),
  },
  mounted() {
    ;(function(i, s, o, g, r, a, m) {
      i['MagicBellObject'] = r
      ;(i[r] =
        i[r] ||
        function() {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://assets.magicbell.io/magicbell.min.js', 'magicbell')

    var target = this.$refs.notificationsInbox
    var options = {
      apiKey: 'f7b6b5239958218a4cb78d46907e8fc846368295',
      userExternalId: this.userId, // @TODO Replace with the logged in user's email
      // You can use userExternalId instead of userEmail -  https://bit.ly/3oiDSAe
      onNotificationClick: function(notification) {
        if (notification.actionUrl) window.open(notification.actionUrl, '_blank')
      },
      height: 500,
      theme: {
        icon: { borderColor: 'rgba(75,85,99)', width: '24px' },
        unseenBadge: { backgroundColor: '#DF4759' },
        header: { backgroundColor: '#f5f5f5', textColor: '#6454f3', borderRadius: '8px' },
        footer: { backgroundColor: '#f5f5f5', textColor: '#6454f3', borderRadius: '8px' },
        notification: {
          default: { textColor: '#15091F', borderRadius: '8px', backgroundColor: '#6454f3' },
          unseen: { backgroundColor: '#6454f3', textColor: '#15091F', borderRadius: '8px' },
          unread: { backgroundColor: '#6454f3', textColor: '#15091F', borderRadius: '8px' },
        },
      },
    }

    window.magicbell('render', target, options)
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.Notifications {
}
</style>
