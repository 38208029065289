<template>
  <div class="FormVersionViewer flex flex-row items-center">
    <a-dropdown v-model="dropdownOpen" :trigger="['click']">
      <a-menu slot="overlay" @click="onSelectFormVersion($event.key)">
        <a-menu-item v-for="button in formButtons" :key="button.key">
          <!-- <Icon type="far" :name="button.icon || 'code-fork'" class="mr-1" /> -->
          {{ button.text }}
          <strong>{{ button.editsText }}</strong>
        </a-menu-item>
        <a-menu-item key="show-more" v-if="limit > 0 && highestVersion >= 10" @click="limit += 10">
          + Show 10 More
        </a-menu-item>
        <a-menu-item key="show-100-more" v-if="limit > 10" @click="limit += 100">
          + Show 100 More
        </a-menu-item>
        <a-menu-item key="show-all" v-if="limit > 10" @click="limit = -1">
          + Show All
        </a-menu-item>
      </a-menu>
      <a-dropdown :trigger="['contextmenu']">
        <div class="subpage-switcher px-2 py-1.5 mb-0.5 rounded cursor-pointer hover:bg-gray-100">
          {{ currentFlowButton && currentFlowButton.short }}
          {{ versionHasUnsavedChanges ? '*' : '' }}
          <Icon type="fas" name="sort" class="ml-1 text-sm text-gray-400" />
        </div>
        <a-menu slot="overlay">
          <a-menu-item
            v-for="button in formButtons"
            :key="button.key"
            @click="compareVersionId = button.key"
          >
            {{ button.text }} {{}}
            <strong>{{ button.editsText }}</strong>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-dropdown>
    <template v-if="!hideSaveButton">
      <a-tooltip v-if="hasUnsavedFlowChanges">
        <template v-if="isNotLatestVersion" slot="title">
          There are more recent changes at: v{{ highestVersion }}
        </template>
        <template
          v-else-if="currentFlowButton && currentFlowButton.otherAuthors.length > 0"
          slot="title"
        >
          This version is also being edited by
          {{ currentFlowButton && currentFlowButton.otherAuthors.join(' + ') }}
        </template>
        <template v-else slot="title">Saves as v{{ highestVersion + 1 }}</template>
        <a-button
          :icon="isNotLatestVersion ? 'exclamation' : 'save'"
          :type="isNotLatestVersion ? 'danger' : 'primary'"
          :class="{
            hasEdits:
              !isNotLatestVersion && currentFlowButton && currentFlowButton.otherAuthors.length > 0,
          }"
          :loading="isSavingFlowChanges"
          @click="onClickSave('saved')"
        >
          Save
        </a-button>
      </a-tooltip>
      <a-tooltip class="mr-4" v-else>
        <template slot="title">Updates v{{ version }}</template>
        <a-button v-if="currentFlowStatus === 'prod'" :disabled="true" type="link" icon="save">
          Saved
        </a-button>
        <a-button
          v-else-if="isAllowedToSave && currentFlowStatus === 'staging'"
          icon="save"
          type="primary"
          :loading="isSavingFlowChanges"
          @click="saveFlow('prod')"
        >
          Promote to Prod
        </a-button>
        <a-button
          v-else-if="!isAllowedToSave && currentFlowStatus === 'staging'"
          icon="save"
          type="link"
          :disabled="true"
        >
          Promote to Prod
        </a-button>
        <a-button
          v-else-if="['saved', 'draft'].includes(currentFlowStatus)"
          icon="save"
          type="primary"
          :loading="isSavingFlowChanges"
          @click="saveFlow('staging')"
        >
          Promote to Staging
        </a-button>
        <a-button v-else :disabled="true" type="link" icon="save">Saved</a-button>
      </a-tooltip>
    </template>
    <a-tooltip v-if="hasUnsavedFlowChanges">
      <template slot="title">Discard Changes</template>
      <a-button icon="delete" type="link" @click="discard" />
    </a-tooltip>
    <VersionCompare
      :formHistories="formHistories"
      :formButtons="formButtons"
      :allFormVersions="allFormVersions"
    />

    <a-modal v-model="modalWarning" title="Before you save..." @ok="onClickOk">
      The most recent version is v{{ highestVersion }}, published by
      {{ formButtons[0] && formButtons[0].author }} on
      {{ formButtons[0] && formButtons[0].dateString }}. You are currently making edits on v{{
        version
      }}, an older version. Do you still want to save?
    </a-modal>
  </div>
</template>
<script>
import Firebase from 'firebase/app'
import 'firebase/firestore'
import { mapGetters, mapState } from 'vuex'

import { FLOWS_COLLECTION } from '@/constants'
import Filters from '@/setup/filters'

import VersionCompare from './mainEditor/VersionCompare.vue'

const getSimpleDate = Filters.find(f => f.name === 'timeSimpleDate').function

export default {
  components: { VersionCompare },
  name: 'FormVersionViewer',
  inject: { _getRegistered: { default: () => () => {} } },
  props: { id: String, hideSaveButton: Boolean },
  data() {
    return {
      allFormVersions: [],
      liveFlow: null,
      hide: false,
      compareVersionId: false,
      modalWarning: false,
      isEditor: false,
      dropdownOpen: false,
      limit: 10,
    }
  },
  computed: {
    ...mapState(['versionData', 'isSavingFlowChanges', 'hasUnsavedFlowChanges']),
    ...mapGetters(['isAdmin', 'userId', 'user']),
    saveFlow() {
      const empty = () => {}
      return this._getRegistered('save') || empty
    },
    isAllowedToSave() {
      return this.isAdmin || this.$store.getters.allowPushLive || this.isEditor
    },
    discard() {
      const empty = () => {}
      return this._getRegistered('discard') || empty
    },
    versionId() {
      return this.versionData && this.versionData.id
    },
    version() {
      return this.versionData && this.versionData.version
    },
    versionHasUnsavedChanges() {
      return this.allFormVersions.some(f => f.saved === false && f.version === this.version)
    },
    formHistories() {
      const forms = this.allFormVersions
        .filter(f => f.saved)
        .map(f => ({ ...f, id: f.id, form: JSON.parse(f.form || '{}') }))
      return forms.sort((a, b) => b.version - a.version)
    },
    currentHistory() {
      const ver = this.formHistories.find(f => this.versionId === f.id)
      return ver
    },
    compareHistory() {
      const ver = this.formHistories.find(f => this.compareVersionId === f.id)
      return ver
    },
    comparisonData() {
      if (!this.currentHistory || !this.compareHistory) return []
      const arr =
        this.compareHistory.version < this.currentHistory.version
          ? [this.compareHistory, this.currentHistory]
          : [this.currentHistory, this.compareHistory]

      return arr.map(d => d.form)
    },
    highestVersion() {
      return (this.formHistories[0] && this.formHistories[0].version) || 0
    },
    highestHistoryData() {
      return this.formHistories[0] || {}
    },
    isNotLatestVersion() {
      return this.highestVersion !== this.version
    },
    formButtons() {
      // const now = new Date()
      const versionsWithEdits = this.allFormVersions
        .filter(f => !f.saved)
        .reduce((acc, f) => {
          acc[f.version] = acc[f.version] ? acc[f.version].concat(f.author) : [f.author]
          return acc
        }, {})

      return this.formHistories
        .map(f => {
          const flowIsLive = this.liveFlow && `version-${this.liveFlow.version}` === f.id
          const statusText = getStatusText(f.status, flowIsLive)
          const hasEdits = versionsWithEdits[f.version]
            ? `+ Edits from ${versionsWithEdits[f.version].map(a => a.userName).join(' + ')}`
            : ''
          const updatedAt = new Date(f.updatedAt && f.updatedAt.seconds * 1000)
          const dateString = getSimpleDate(updatedAt)
          // const isToday = now.toDateString() === updatedAt.toDateString()
          // const dateString = isToday
          //   ? updatedAt.toLocaleTimeString()
          //   : updatedAt.toLocaleDateString()
          return {
            author: f.author.userName,
            dateString,
            short: `${statusText}v${f.version}`,
            text: `${statusText}v${f.version} - ${f.author.userName} ${
              typeof f.from_version === 'number' && f.version - f.from_version !== 1
                ? `[From v${f.from_version}]`
                : ''
            } (${dateString})`,
            otherAuthors: (versionsWithEdits[f.version] || [])
              .filter(a => a.userId !== this.userId)
              .map(a => a.userName),
            editsText: hasEdits,
            key: f.id,
            isCurrent: this.versionId === f.id,
            status: f.status,
            version: f.version,
          }
        })
        .filter(f => f.status)
    },
    currentFlowButton() {
      return this.formButtons.find(f => f.isCurrent)
    },
    currentFlowVersion() {
      return this.currentFlowButton && this.currentFlowButton.key
    },
    currentFlowStatus() {
      if (this.liveFlow && this.currentFlowButton) {
        if (this.liveFlow.version === this.currentFlowButton.version) return 'prod'
      }
      return this.currentFlowButton && this.currentFlowButton.status
    },
  },
  async mounted() {
    console.log('Checking warrant access')
    if (this.$warrant) {
      const isEditor = await this.$warrant.hasWarrant('flow', 'general', 'editor')
      console.log('Warrant found, has access:', isEditor)
      this.isEditor = isEditor
    } else {
      console.warn('No Warrant found')
    }
  },
  beforeDestroy() {
    this.$store.commit('setFlowVersions', [])
    this.$store.commit('setVersionData', {})
  },
  watch: {
    comparisonData: {
      handler(v) {
        this.$store.commit('setComparisonData', v)
      },
      immediate: true,
    },
    compareVersionId: {
      handler(v) {
        this.$store.commit('setCompareVersionId', v)
      },
      immediate: true,
    },
    highestVersion: {
      handler(v) {
        // this.$emit('input', v)
        this.$store.commit('setHighestVersion', v)
      },
      immediate: true,
    },
    limit: {
      handler(v) {
        const baseRef = Firebase.firestore()
          .collection(FLOWS_COLLECTION)
          .doc(this.id)

        let ref = baseRef.collection('history').orderBy('version', 'desc')
        if (v > 0) ref = ref.limit(v)
        this.$bind('allFormVersions', ref)
      },
    },
    id: {
      async handler(id) {
        if (!id) return
        this.$store.commit('setVersionData', {})
        const baseRef = Firebase.firestore()
          .collection(FLOWS_COLLECTION)
          .doc(id)
        let ref = baseRef.collection('history').orderBy('version', 'desc')
        if (this.limit > 0) ref = ref.limit(this.limit || 10)
        await Promise.all([this.$bind('allFormVersions', ref), this.$bind('liveFlow', baseRef)])
        this.loadVersionData()
      },
      immediate: true,
    },
    formHistories: {
      handler(histories) {
        this.$store.commit('setFlowVersions', histories)
      },
    },
  },
  methods: {
    onSelectFormVersion(id) {
      if (['show-all', 'show-more'].includes(id)) {
        /* Needed because ant-design doesn't give us a way to preserve the dropdown open state on clicking something (for some reason) */
        this.dropdownOpen = true
        return
      }
      this.dropdownOpen = false
      const formHistory = this.formHistories.find(f => f.id === id)
      if (!formHistory) return
      const versionData = { id: formHistory.id, version: formHistory.version }
      this.$store.commit('setVersionData', versionData)
    },
    loadVersionData() {
      const unsavedChanges = this.allFormVersions.find(d => d.id === `unsaved-${this.userId}`)
      const doc = this.allFormVersions && this.allFormVersions[0]
      let version = undefined
      if (unsavedChanges) version = unsavedChanges.version
      else if (doc) version = doc.version

      const id = version !== undefined ? `version-${version}` : undefined
      const versionData = { id, version }
      this.$store.commit('setVersionData', versionData)
    },
    onClickOk() {
      this.modalWarning = false
      this.saveFlow('saved')
    },
    onClickSave() {
      const shouldShowModal = this.isNotLatestVersion
      if (shouldShowModal) {
        this.modalWarning = true
      } else this.saveFlow('saved')
    },
  },
}

function getStatusText(key, isLive) {
  if (isLive) return '[Live] '
  switch (key) {
    case 'staging':
      return '[Staging] '
    case 'prod':
      return '[Live] '
    case undefined:
      return '[Legacy] '
    default:
      return ''
  }
}
</script>
<style lang="scss" scoped>
.hasEdits {
  background: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
  border-color: rgba(217, 119, 6, var(--tw-bg-opacity)) !important;
}
</style>
