<template>
  <div class="ShareFlow">
    <div class="section-heading flex">
      <h3 class="flex-grow align-center text-lg">
        Embed Code
      </h3>
    </div>
    <a-checkbox v-model="popup">Embed as a Popup Flow</a-checkbox>
    <div class="flex mt-2">
      <h4 class="flex-grow">
        <strong>Part 1:</strong> Make sure this is in the HEAD of all pages on your site:
      </h4>
      <div class="ml-2">
        <a-button size="small" icon="copy" @click="copyEmbedCode('part_1')">Copy</a-button>
      </div>
    </div>
    <pre class="select-all cursor-text">{{ embedCodePart1.join('\n') }}</pre>
    <template>
      <div class="flex">
        <h4 class="flex-grow"><strong>Part 2:</strong> {{ part2Instructions }}</h4>
        <div class="ml-2">
          <a-button size="small" icon="copy" @click="copyEmbedCode('part_2')">Copy</a-button>
        </div>
      </div>
      <pre v-if="popup">{{
        form.popup_selector ||
          'No selector found - make sure this Flow has a popup trigger selector set.'
      }}</pre>
      <pre v-else class="select-all cursor-text">{{ embedCodePart2.join('\n') }}</pre>
    </template>
    <div class="section-heading flex">
      <h3 class="flex-grow align-center text-lg">
        Standalone Flow
      </h3>
      <a-space align="center">
        <a-button size="small" icon="copy" @click="copyStandaloneUrl">Copy</a-button>
      </a-space>
    </div>
    <a :href="standaloneUrl" target="_blank" class="share-link whitespace-pre-wrap">
      <a-icon type="link" />
      {{ standaloneUrl }}
    </a>
    <img
      class="mt-2"
      style="max-width: 12rem;"
      :src="`https://qr.eletto.dev/${standaloneUrl}`"
      alt="QR Code"
    />
  </div>
</template>
<script>
import { copyToClipboard } from '@/helpers/clipboard'
import getOptions from './helpers/options'
export default {
  name: 'ShareFlow',
  props: {
    id: String,
    form: Object,
  },
  data() {
    return {
      popup: false,
    }
  },
  computed: {
    options() {
      return getOptions(this.form)
    },
    standaloneUrl() {
      return `https://flows.trysavvy.com/flow/${this.id}`
    },
    part2Instructions() {
      return this.popup
        ? `Make sure any buttons that should trigger the popup match this selector:`
        : `Place this where you'd like the Flow to appear:`
    },
    embedCodePart1() {
      const embedCode = []

      if (formHasGooglePlaces(this.form)) {
        embedCode.push(
          `<script async src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDalHb-c9FFBVgDuOegNyZpWd-8bwvQikk&libraries=places">${'<'}/script>`
        )
      }

      // <script type="module" src="https://unpkg.com/@power-elements/stripe-elements/stripe-elements.js?module">${'<'}/script>\n
      if (this.options.find(o => o.type === 'StripeCheckout')) {
        embedCode.push(`<script async src="https://js.stripe.com/v3/">${'<'}/script>`)
      }

      const primaryProductId =
        (this.$store.state.products[0] && this.$store.state.products[0].id) || null
      const analyticsSnippet = [
        `<script async src="https://cdn.heysavvy.com/wc/savvy.min.js"><` + `/script>`,
        `<savvy-smart-form id="${primaryProductId}"></savvy-smart-form>`,
      ]
      embedCode.push(analyticsSnippet[0])
      embedCode.push(analyticsSnippet[1])

      const scriptDownload =
        `<script async src="https://flows.trysavvy.com/flows/savvy-flow.min.js">` + '<' + '/script>'
      embedCode.push(scriptDownload)

      if (this.popup) embedCode.push(this.flowScript)

      return embedCode
    },
    embedCodePart2() {
      const embedCode = []

      embedCode.push(this.flowScript)

      return embedCode
    },
    flowScript() {
      return `<savvy-flow id="${this.id}"${this.popup ? ` popup="true"` : ``}></savvy-flow>`
    },
  },
  watch: {},
  methods: {
    copyStandaloneUrl() {
      copyToClipboard(this.standaloneUrl)

      this.$message.success('Copied to Clipboard')
    },
    copyEmbedCode(part) {
      const code =
        part === 'part_1' ? this.embedCodePart1 : part === 'part_2' ? this.embedCodePart2 : null

      if (!code) return

      copyToClipboard(code.join('\n'))

      this.$message.success('Copied to Clipboard')
    },
  },
}
function formHasGooglePlaces(form) {
  const finder = co =>
    co.fetch_options &&
    co.fetch_preset === 'location' &&
    co.fetch_preset_location_type === 'places_autocomplete'
  const globalsHave = Array.isArray(form.components) && form.components.find(finder)
  const pagesHas =
    Array.isArray(form.pages) && form.pages.some(p => p.components && p.components.find(finder))
  return Boolean(pagesHas) || Boolean(globalsHave)
}
</script>
