import Firebase from 'firebase/app'
import 'firebase/firestore'

export default async function updateActiveGroup(userId, group, setRecent) {
  const update = { activeGroupId: group.value }
  if (setRecent) {
    const recentActiveGroupIds = (
      await Firebase.firestore()
        .collection('users')
        .doc(userId)
        .get()
    ).get('recentActiveGroupIds')
    update.recentActiveGroupIds = Array.from(
      new Set([group.value, ...(recentActiveGroupIds || [])])
    )
  }
  await Firebase.firestore()
    .collection('users')
    .doc(userId)
    .update(update)
}
